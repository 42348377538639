<template>
<div>
  <van-nav-bar title="订单详情" left-text=""  left-arrow @click-left="onClickLeft"  />
  <van-cell-group title="订单信息">
    <van-cell title="联系人" :value="model.name" />
	<van-cell title="联系电话" :value="model.tel" />
	<van-cell title="地区" :value="model.area" />
	<van-cell title="详细地址" :value="model.address" />
	<van-cell v-if="model.union == 'switching'" title="新地区" :value="model.newarea" />
	<van-cell v-if="model.union == 'switching'" title="新详细地址" :value="model.newaddress" />
	<van-cell title="产品类型" :value="model.category_name" />
	<van-cell title="产品型号" :value="model.goods_code" />
	<van-cell title="机器编号" :value="model.goods_num" />
	<van-cell title="出厂日期" :value="model.buy_date" />
	<van-cell v-if="model.union == 'maintenance'" title="故障类型" :value="model.errortype" />
	<van-cell v-if="model.union == 'maintenance'" title="故障类型" :value="model.errorinfo" />
	<van-cell v-if="model.union == 'maintenance'" title="故障图片" />
	<van-row :gutter="0">
      <van-col v-for="(item, index) in model.errorimg" :key="index" :span="6" style="border:1px solid #DCDFE6">
        <van-image style="width:100%;height:100px;" :src="item.file_url" @click="showerrorimg(item.file_url)" title="点击查看大图" />	  
      </van-col>
    </van-row>
	<van-popup v-model:show="errorimginfo"><van-image :src="errorimg_url" /></van-popup>
  </van-cell-group>
  <div v-show="handle_fen">
	  <van-cell-group title="人员分配">
		<van-form @submit="formSubmit">
			<van-cell title="订单状态" ><template #right-icon><van-tag type="primary">{{model.status_name}}</van-tag></template></van-cell>
			<van-field v-model="form.allot_name" is-link readonly label="跟进人员" placeholder="选择跟进人员" @click="shownamepopup = true"/>
			<van-popup v-model:show="shownamepopup" round position="bottom">
			<van-picker :columns="member_list" show-toolbar :loading="member_loading"  @cancel="shownamepopup = false" @confirm="memberconfirm" confirm-button-text="确定" title="选择人员" cancel-button-text="取消"	/></van-popup>
			<van-button class="form_botton" round block type="primary" native-type="submit">提交</van-button>
		</van-form>
	  </van-cell-group>
  </div>
  <div v-show="handle_member">
	  <van-cell-group title="订单处理">
		<van-form @submit="finishSubmit">
			<van-cell title="订单状态" ><template #right-icon><van-tag type="primary">{{model.status_name}}</van-tag></template></van-cell>
			<van-button class="form_botton" round block type="primary" native-type="submit">完成订单</van-button>
		</van-form>
	  </van-cell-group>
  </div>
</div>
</template>
<script lang="ts">
import { info, get_qiyewechet_allmember, allot, finish } from '@/api/aftersalse'
import { showSuccessToast, Dialog } from 'vant';
export default {
  name: 'Aftersales',
	data() {
	  return {
	    errorimginfo: false,
		errorimg_url: '',
		handle_fen:false,
		handle_member:false,
		model: {
			allot_id:0,
		},
		type:this.$route.query.type,
		form:{
		  allot_name:'',
		  allot_id:'',
		  id:'',
		  union:'',
		  type:0,
		},
		member_list:[],
		member_loading:true,
		shownamepopup:false,
	  }
	},
	created() {
	  this.info(this.$route.query.id,this.$route.query.union,this.$route.query.type)
	  this.get_member_list()	  
	},
	methods: {
	  info(id,union){
	  	info({id: id,union: union}).then(res => {
			this.model=res.data.data
			this.form.allot_name=this.model.allot_name
			this.form.allot_id=this.model.allot_id
			this.form.id=this.model.id
			this.form.union=this.model.union
			this.form.id=this.model.id
			this.loading = false
			if(this.model.status<99 && this.type==0){//分配栏目是否显示,
				this.handle_fen=true
			}
			if(this.model.status<99 && this.type==1){//分配栏目是否显示,
				this.handle_member=true
			}
		  }).catch(() => {
			this.loading = false
		})
	  },
	  showerrorimg(url){
	  	this.errorimginfo = true
		this.errorimg_url = url
	  },
	  onClickLeft(){
	    this.$router.back()
	  },
	  get_member_list(){
	  	get_qiyewechet_allmember().then(res => {
			this.member_list=res.data.data
			this.member_loading=false
		  }).catch(() => {
		})
	  },
	  memberconfirm(value){
		//alert(JSON.stringify(value))
	  	this.form.allot_name=value.text
		this.form.allot_id=value.value
		this.shownamepopup=false
	  },
	  //分配人员提交
	  formSubmit(){
		 // alert(JSON.stringify(this.form))
		  allot(this.form).then(res => {
			//showSuccessToast('分配成功');
			this.$toast.success('分配成功')
			this.$router.push('/orderlist')
		    }).catch(() => {
		  })
	  },
	  //完成订单提交
	  finishSubmit(){
	  	Dialog.confirm({
		  title: '提示',
		  message: '是否确定完成订单',
		}).then(() => {
			finish(this.form).then(res => {
				this.$toast.success('提交成功')
				this.$router.push('/myorderlist')
				}).catch(() => {
			})
		})
	  },
	}
}
</script>
<style>
.search{ background:#FFF;}
.van-field__body{}
body{height:100%; background:#f0f0f0;}
.van-icon-search{font-size:32px;}
.form_botton{ margin-top: 20px;}
</style>
